.nodeCoverage {
  border-top: 1px solid var(--grey-darken);
  background-color: var(--black);
  display: flex;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    padding: 267px 0 52px;
    background-image: url(../../images/map-mobile.svg);
    background-position: 57% 4%;
    background-size: contain;
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
    button {
      margin-top: 0;
    }
  }
  @media (min-width: 768px) {
    padding: 120px 0 120px;
    background-image: none;
    button {
      margin-top: 0;
      padding: 0 30px;
    }
  }
  @media (min-width: 1025px) {
    padding: 220px 0 275px;
    background-image: url(../../images/map.webp);
    background-size: auto;
    background-position: center;
  }
  .preTitle,
  .title,
  .description {
    text-align: center;
    @media (max-width: 1024px) {
      text-align: left;
    }
  }
  .description {
    font-size: 20px;
    margin-top: 45px;
    line-height: 32px;
    @media (min-width: 1025px) {
     margin: 24px auto 120px;
     width: 58%;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 24px;
      margin: 16px auto 40px;
    }
  }
  &_block {
    border-radius: 8px;
    border: 1px solid #323242;
    padding: 32px 24px;
    width: 335px;
    height: 366px;
    background: linear-gradient(209.18deg, #24242E -3.47%, #1C1C24 98.64%);
    margin: 0 auto 24px;
    @-moz-document url-prefix() {
      border: 1px solid var(--grey-darken);
      background-color: rgba(28, 28, 36, 0.98);
    }
    @media (min-width: 768px) {
      width: 100%;
      height: 408px;
      padding: 64px 64px;
    }
    @media (min-width: 1024px) {
      width: 464px;
      margin-bottom: 16px;
    }
    &-img {
      padding: 0 0 0 19px;
    }
    span {
      font-size: 14px;
      line-height: 24px;
      color: var(--grey);
    }
    h4 {
      color: var(--white);
      font-family: var(--font-primary);
      font-size: 20px;
      font-weight: var(--font-primary-weight-normal);
      margin-bottom: 28px;
    }
    p {
      font-family: var(--font-primary);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #77778C;
      margin-bottom: 24px;
    }
    ul {
      list-style: none;
      margin-bottom: 0;
      padding: 20px 0 10px 0;
      li {
        color: var(--grey);
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: var(--font-secondary-weight-normal);
        line-height: 24px;
        border-top: 1px solid #323242;
        display: flex;
        justify-content: space-between;
        padding-top: 8px;
        padding-bottom: 8px;
        span {
          color: var(--white-darken);
        }
        &:last-child {
          border-bottom: none;
        }
        &:first-child {
          border-top: none;
        }
      }
    }
    h2 {
      color: var(--white);
      font-family: var(--font-primary);
      font-weight: var(--font-primary-weight-bold);
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 4px;
      @media (max-width: 767px) {
        text-align: left;
      }
    }
    &__cards {
      display: flex;
      justify-content: space-around;
      margin-bottom: 59px;
      &-card {
        width: 48px;
        height: 48px;
        border-radius: 6px;
        border: 1px solid #323242;
        background-color: rgba(25, 25, 32, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 4px;
        @media (min-width: 768px) {
          width: 56px;
          height: 56px;
        }
        &::before {
          content: '';
          border-bottom: 1px solid #323242;
          position: absolute;
          top: 50%;
          width: 100%;
        }
        span {
          color: var(--white);
          font-family: var(--font-primary);
          font-size: 32px;
          line-height: 30px;
        }
        @media (max-width: 767px) {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
  &_block_first {
    margin-left: 0;
    @media (max-width: 767px) {
      margin: 0 auto 24px;
    }
    @media (min-width: 1366px) {
      margin-left: 0;
    }
  }
}
.transaction-explorer {
  margin-top: 80px;
  @media (max-width: 767px) {
    margin-top: 32px;
  }
}
.power-by {
  display: flex;
  align-items: center;
  justify-content: center;

}
.block-container {
  padding: 0 8px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.custom_col {
   width: 100%;
 }
.custom_row {
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
