.solutions {
  border-top: 1px solid var(--grey-darken);
  background-color: var(--black-darken);
  display: flex;
  padding: 188px 0 188px;
  @media (max-width: 767px) {
    background-image: url(../../images/sphere-glow.webp);
    background-repeat: no-repeat;
    background-size: 520px;
    background-position: 95px 0;
    padding-top: 318px;
  }
  @media (max-width: 767px) {
    padding-bottom: 72px;
    background-position: -27px 57px;
    background-size: 428px;
  }
  &_background {
    @media (min-width: 1025px) {
    background-image: url(../../images/sphere-glow.webp);
    background-size: 924px 834px;
    background-repeat: no-repeat;
    padding-bottom: 15%;
    height: 834px;
    position: absolute;
    top: -45px;
    width: 924px;
    left: -378px;
    }
  }
  &_col_left {
    width: 0;
    @media (min-width: 1025px) {
      position: relative;
      width: 50%;
    }
  }
  &_col_right {
    width: 100%;
    z-index: 1;
    padding: 0 15px;
    @media (min-width: 768px) {
      padding: 0;
    }
    @media (min-width: 1025px) {
      width: 50%;
    }
  }
  .title {
    margin-bottom: 24px;
  }
  .description {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &_block {
    margin-top: 56px;
    border-radius: 14px;
    border: 1px solid var(--grey-darken);
    background: linear-gradient(209.18deg, #24242E -3.47%, #1C1C24 98.64%);
    padding: 64px 64px 72px;
    @media (max-width: 767px) {
      padding: 24px 32px;
    }

    h4 {
      color: var(--white);
      font-family: var(--font-primary);
      font-weight: var(--font-primary-weight-bold);
      margin-bottom: 4px;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }
    p {
      color: var(--grey);
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: var(--font-secondary-weight-normal);
      line-height: 24px;
      margin-bottom: 16px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.secondary-p{
  margin-top: 40px;
  margin-bottom: 0;
}

