.contacts {
  border-top: 1px solid #292936;
  background-color: var(--black-darken);
  padding: 180px 0 265px;
  @media (max-width: 767px) {
    padding: 73px 21px 60px;
  }
  &_left {
    border: 1px solid var(--grey-darken);
    background: linear-gradient(209.18deg, #24242E -3.47%, #1C1C24 98.64%);
    border-radius: 15px;
    padding: 64px 64px 80px;
    max-width: 464px;
    h2 {
      text-transform: uppercase;
      font-family: var(--font-primary);
      font-size: 32px;
      line-height: 45px;
      font-weight: var(--font-primary-weight-bold);
      color: var(--white);
      margin-bottom: 13px;
    }
    &__block {
      padding: 14px 0;
      p {
        color: var(--white);
        font-family: var(--font-primary);
        font-weight: var(--font-primary-weight-bold);
        font-size: 20px;
        margin-bottom: 0;
        line-height: 32px;
      }
      a {
        color: var(--grey);
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: var(--font-secondary-weight-normal);
        line-height: 24px;
        display: block;
      }
    }
    &__icons {
      padding-top: 8%;
      a {
        color: var(--green);
        padding-right: 6%;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  &_right {
    padding: 84px 0 0 0;
    margin-left: 0;
    max-width: 624px;
    @media (max-width: 767px) {
      padding: 0 15px;
      margin-left: 0;
    }
    .title {
      margin-bottom: 24px;
      @media (max-width: 767px) {
        margin: 0 0 16px;
      }
    }
    .description {
      font-size: 20px;
      line-height: 32px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 9px;
      }
    }
    .title,
    .preTitle,
    .description {
      text-align: center;
    }
    &__info {
      display: flex;
      padding-top: 31px;
      justify-content: space-around;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      &-block {
        border-left: 2px solid var(--green);
        padding-left: 16px;
        height: 64px;
        margin-top: 9px;
        @media (max-width: 767px) {
          margin-top: 0;
        }
        p {
          color: var(--grey);
          font-family: var(--font-secondary);
          font-size: 14px;
          font-weight: var(--font-secondary-weight-normal);
          line-height: 24px;
          margin-bottom: 0;
        }
        span {
          color: var(--white);
          font-size: 32px;
          line-height: 40px;
          font-family: var(--font-primary);
          font-weight: var(--font-primary-weight-bold);
          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
    &__button {
      margin-top: 87px;
      display: flex;
      .button {
        &.size_normal {
          width: 300px;
        }
      }
      @media (max-width: 767px) {
        margin: 64px 0 0;
        justify-content: space-between;
        flex-direction: column;
        .button {
          &:first-child {
            margin-right: 10px;
          }
          &.size_normal {
            min-width: 100%;
            letter-spacing: normal;
          }
        }
      }
    }
  }
  .toggle {
    height: auto;
    border-bottom: 1px solid var(--gray-darken);
    border-top: 1px solid var(--gray-darken);
    &:last-child {
      border-top: none;
    }
    &_header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--white);
      font-family: var(--font-primary);
      text-transform: uppercase;
      font-size: 12px;
      font-weight: var(--font-primary-weight-bold);
      line-height: 24px;
      height: 62px;
      &:active,
      &:hover,
      &:focus {
        color: var(--white);
      }
    }
    &_content {
      padding-bottom: 30px;
      a {
        color: var(--grey);
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: var(--font-secondary-weight-normal);
        line-height: 22px;
        display: block;
      }
      .icons {
        padding-top: 15%;
        display: flex;
        justify-content: space-between;
        a {
          color: var(--green);
        }
      }
    }
  }
  &_row {
    justify-content: center;
  }
}
