.animatedArrow {
  display: flex;
  align-items: center;
  color: var(--green);
  font-family: var(--font-primary);
  font-weight: var(--font-primary-weight-bold);
  cursor: pointer;
  transition: all 0.2s;
  text-transform: uppercase;
  font-style: normal;
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 0.1em;
  &.center {
    justify-content: center;
  }
  &:hover{
    text-decoration: none;
    color: var(--green);
    .the-arrow {
      .shaft{
        width: 20px;
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        background-color: var(--green);
      }
    }
  }
  .the-arrow {
    width: 13px;
    transition: all 0.2s;
    padding-left: 10px;
    & .shaft {
      width: 13px;
      background-color: var(--green);
      display: block;
      height: 1px;
      position: relative;
      transition: all 0.2s;
      transition-delay: 0;
      will-change: transform;
      &:before,
      &:after {
        width: 5px;
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        background-color: var(--green);
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        top: 0;
        right: 0;
        transition: all 0.2s;
        transform: rotate(0);
      }
      &:before {
        transform-origin: top right;
        transform: rotate(40deg);
      }
      &:after {
        transform-origin: bottom right;
        transform: rotate(-40deg)
      }
    }
  }
}
