.offers {
  background-color: var(--black);
  padding: 6.2% 0 0;
  @media (max-width: 1440px){
    padding: 5% 0 0;
  }
  .title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 125px;
    padding-top: 15px;
  }

  .zIndex {
    z-index: 1;
    padding-right: 3px;
  }
  .preTitle {
    line-height: 24px;
  }

  @media (max-width: 767px) {
    padding: 44px 0 0;
    .preTitle,
    .title {
      margin-left: 15px;
      margin-bottom: 25px;
    }
    .preTitle {
      margin-bottom: 0;
    }
    .title{
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }
    &__col {
      padding: 0;
    }

  }

  @media (min-width: 1025px) {
    .preTitle {
      padding-top: 80px;
      margin-left: 5px;
      margin-bottom: 26px;
    }

    .preTitle,
    .title {
      padding-left: 0;
    }

    .title {
      padding-top: 0;
    }

    .title span {
      white-space: nowrap;
    }

    .title {
      margin-bottom: 16px;
    }
  }
  @media (min-width: 1367px) {
    .preTitle {
      padding-top: 80px;
      margin-left: 5px;
      margin-bottom: 26px;
    }

    .preTitle,
    .title {
      padding-left: 0;
    }

    .title {
      padding-top: 0;
    }

    .title span {
      white-space: nowrap;
    }

    .title {
      margin-bottom: 115px;
    }
  }

  &_block {
    position: relative;
    appearance: none;
    margin-bottom: 19px;
    outline: none;
    overflow: hidden;
    transition: 0.3s;
    border-radius: 8px;
    background: linear-gradient(209.18deg, #24242E -3.47%, #1C1C24 98.64%);
    box-shadow: 8px 8px 32px rgba(24, 24, 31, 0.25);
    padding: 1px;
    height: 336px;
    width: 304px;
    //border: 1px solid #303040;

    @media (min-width: 768px) and (max-width: 1025px)  {
      width: 100%;
    }
     &::before {
       --size: 0;

       content: '';
       position: absolute;
       left: var(--x);
       top: var(--y);
       width: var(--size);
       height: var(--size);
       background: radial-gradient(circle closest-side at 200px 200px,#2ee533, #303040);
       transform: translate(-50%, -50%);
       transition: width 0.9s ease, height 0.9s ease;
    }
    &__low {
      margin-top: 49px;
    }

    &__lower {
      margin-top: 90px;
      margin-bottom: 13px;
    }

    &__high {
      margin-top: -90px;
    }

    &__higher {
      margin-top: -50px;
    }
    &_md_lower {
      margin-top: 208px;
    }
    &_md_low {
      margin-top: 108px;
    }

    @media (max-width: 767px) {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 72px 59px 78px;
      height: 336px;
      border-radius: 8px;
      width: calc(100% - 14px) !important;
      margin: 0 auto 19px;
      margin-left: 30px;
      box-shadow: 0px 3px 13px 0px #0000004f;

      &:hover {
        &::after {
          contain: none;
          background: none;
        }
        &::before {
          display: none;
        }
      }

    }
    @media (max-width: 768px) {
      &__high {
        margin-top: 0;
      }

    }

    &:hover {
      .before {
        --size: 400px;

      }
    }

    &-box {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      position: relative;
      pointer-events: none;
      background: #1c1c24;
      line-height: 40px;
      padding: 72px 42px;
      border: 1px solid var(--grey-darken);
      height: calc(100% - 1px);
      width: 100%;
    }

    h4 {
      color: var(--white);
      font-family: var(--font-primary);
      font-size: 20px;
      line-height: 32px;
      font-weight: var(--font-primary-weight-bold);
      margin-top: 24px;
      margin-bottom: 3px;
      letter-spacing: -0.02em;
    }

    p {
      color: var(--grey);
      font-family: var(--font-secondary);
      font-size: 14px;
      font-weight: var(--font-secondary-weight-normal);
      line-height: 24px;
      text-align: center;
    }
  }

}

//slider
.slick-slider {
  .slick-list {
    padding-left: 0 !important;

    .slick-track {
      display: flex;
      align-items: center;

      .slick-active {
        .offers_block {
          transition: transform 0.4s;
          box-shadow: 0px 4px 18px 0px #000000d1;
        }
      }
    }
  }

  .slick-dots {
    li {
      &.slick-active {
        button {
          &::before {
            color: var(--white)
          }
        }
      }

      button {
        &::before {
          color: #43434c;
          opacity: 1;
        }
      }
    }
  }
}

.offers_block {

  &:hover {
    &::before {
      --size: 400px;
    }
  }
  &-box {

  }
  &::before {

  }
}
.vertical_row {
  flex-direction: column;
}
