.getStarted {
  background-size: 800px 800px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -77px 57px;
  background-color: var(--black);
  position: relative;
  &_row {
    margin: 0;
    @media (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }
  }
  &_menu {
    padding-top: 208px;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    padding-left: 0;
    margin-left: 95px;
    @media (min-width: 768px) and (max-width: 1024px) {
      background-position: -160px 100px;
    }

    ul {
      list-style: none;
      margin-bottom: 95px;
      padding-left: 0;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding-left: 0;
      }
      li {
        border-left: 1px solid #303040;
        padding-left: 16px;
        &.active {
          margin-left: -1px;
          padding-left: 15px;
          border-left: 3px solid #46B549;
          a {
            color: var(--green);
          }
        }
        a {
          color: var(--grey);
          font-family: var(--font-secondary);
          font-style: normal;
          font-size: 20px;
          line-height: 40px;
          font-weight: var(--font-secondary-weight-normal);
          flex-direction: column;
          cursor: pointer;
          &:hover {
            color: var(--green);
            text-decoration: none;
          }
        }
      }
    }
  }
  &_col {
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  &_content {
    flex: 1;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 253px;
    position: relative;
    @media (min-width: 768px){
      background-color: var(--black);
    }
    @media (min-width: 768px) and (max-width: 991px){
      padding: 200px 60px 80px 41px;
    }

    h1 {
      margin-bottom: 0;
      @media (max-width: 1200px) {
        margin-left: 20px;
      }
    }
    .description {
      font-size: 22px;
      line-height: 28px;
    }
    @media (max-width: 1200px) {
      em {
        display: block;
      }
      padding: 58px 0;
    }


    &__block {
      margin-bottom: 56px;
      &:first-of-type {
        margin-bottom: 80px;
      }
        @media (max-width: 1200px) {
          margin-top: 0;
          &:first-of-type {
            margin-bottom: 48px;
          }
        }
      }

    .animatedArrow {
      @media (max-width: 1200px) {
        letter-spacing: inherit;
      }
    }
    .downloadBlock {
      max-width: 784px;
      border-radius: 6px;
      border: 1px solid var(--grey-darken);
      background: var(--black-lighter);
      background-size: contain;
      padding: 80px 80px 120px;
      margin-top: 40px;
      .icon-card {
        margin-bottom: 32px;
      }
      &_card {
        img {
          padding-top: 20px;
        }
        display: flex;
        background: linear-gradient(209.18deg, #24242E -3.47%, #1C1C24 98.64%);
        border: 1px solid #303040;
        box-sizing: border-box;
        box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        margin-top: 60px;
        padding: 40px 80px 48px 40px;
        &_card-content {
          width: 100%;
          margin-left: 40px;
          h4 {
            margin-bottom: 8px;
          }
          @media (max-width: 1200px) {
            margin-top: 38px;
            margin-left: 0;
          }
        }
        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: center;
          padding: 24px 20px 56px;
        }
      }
      @media (max-width: 767px) {
        border: none;
        border-top: 1px solid var(--grey-darken);
        background: none;
        border-radius: 0;
        padding: 30px 20px;
        button {
          margin-bottom: 20px;
        }
        &_slider {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 20px -5px 20px 23px;
          text-align: center;
          font-family: var(--font-primary);
          color: #fff;
          height: 200px;
          svg {
            fill: #fff;
            margin: 0 auto;
            height: 100px !important;
            width: 80px !important;
          }
        }
      }
      @media (min-width: 768px) and (max-width: 991px) {
          padding: 35px 35px 42px;
      }
      &_key {
        padding: 20px 0 15px;
        .theme_ghost {
          margin: 0 !important;
        }
      }
      .theme_ghost {
        min-width: 90px;
        padding: 0 24px;
        margin: 0 5px;
        @media (max-width: 1200px) {
          padding: 0 18px;
          min-width: auto;
          margin: 16px 0 0;
        }
      }
      .button {
        .body_text {
          padding-left: 0;
          text-transform: uppercase;
        }
      }
      .version-links {
        display: flex;
        padding: 32px 0 0;
        a {
          margin-right: 32px;
        }
        .menu {
          a {
            margin-right: 0;
          }
        }
      }
      &.coreWallet {
        background: var(--black-lighter) url("../../images/core-wallet.webp")
          no-repeat left bottom;
        padding-bottom: 390px;
        @media (max-width: 767px) {
          padding-bottom: 100px;
        }
      }
      &.nodeStatus {
        @media (min-width: 1199px) {
          background: var(--black-lighter) url("../../images/core-status.webp");
        }
        padding-bottom: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        .animatedArrow {
          padding: 15px 0 35px;
        }
        .button {
          margin-left: 0;
          margin-bottom: 15px;
          @media (max-width: 1200px) {
            display: none;
          }
        }
        @media (min-width: 968px) {
          p {
            width: 50%;
          }
        }
      }
      &.cpuMiners {
        @media (min-width: 768px) {
          background: var(--black-lighter) url("../../images/luna.webp")
            no-repeat 87% 13%;
        }
        .animatedArrow {
          padding-top: 20px;
        }
        ul {
          padding-left: 15px;
          li {
            &::marker {
              color: var(--grey);
              font-size: 10px;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
      &.androidMiners {
        @media (min-width: 768px) {
          background: var(--black-lighter) url("../../images/distribution.webp")
            no-repeat right center;
        }
        a {
          padding-top: 25px;
        }
      }
      h3 {
        color: var(--white);
        font-size: 32px;
        line-height: 48px;
        font-family: var(--font-primary);
        font-weight: var(--font-primary-weight-bold);
        margin-bottom: 8px;
        @media (max-width: 767px) {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: -0.03em;
        }
      }
      h4 {
        color: var(--white);
        font-family: var(--font-primary);
        font-size: 20px;
        line-height: 32px;
        font-weight: var(--font-primary-weight-bold);
        @media (max-width: 767px) {
          font-size: 22px;
          line-height: 32px;
          letter-spacing: -0.03em;
        }
      }
      p, ul {
        color: var(--grey);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 24px;
        font-weight: var(--font-secondary-weight-normal);
        margin-bottom: 0;
        a {
          color: var(--white);
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
      ul {
        padding-left: 20px;
      }
      hr {
        margin: 36px 0;
        border-color: var(--grey-darken);
      }
      .input-group {
        justify-content: space-between;
        height: 43px;
        border-radius: 8px;
        border: 1px solid var(--gray-darken);
        background-color: var(--black-lighter);
        margin-top: 35px;
        input {
          color: var(--green);
          font-family: var(--font-secondary);
          font-size: 11px;
          font-weight: var(--font-secondary-weight-normal);
          line-height: 22px;
          border: none;
          border-radius: 10px 0 0 10px;
          background-color: var(--black-lighter);
          padding-left: 14px;
          padding-right: 10px;
          width: calc(100% - 47px);
          &:focus {
            outline: none;
          }
        }
        button {
          background-color: var(--black-lighter);
          border: none;
          border-radius: 0 10px 10px 0;
          padding-right: 16px;
          padding-left: 15px;
          margin-bottom: 0;
          svg {
            width: 12px !important;
            height: 12px !important;
          }
          &:focus {
            outline: none;
            box-shadow: 0 0 5px 0 var(--green);
          }
        }
        .vertical {
          height: 19px;
          border-left: 1px solid var(--gray-darken);
          border-top: none;
          margin: auto 0;
        }
      }
      .tabs_header {
        margin-top: 56px;
        @media (max-width: 1200px) {
          border-bottom: 1px solid #434359;
        }
        .nav-tabs {
          padding-left: 0;
          border-bottom: 1px solid rgba(99, 99, 120, 0.2);
          margin-top: 59px;
          display: flex;
          @media (max-width: 1200px) {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            flex-wrap: nowrap;
            margin-top: 24px;
            border-bottom: none;
          }
          .nav-link {
            color: var(--grey);
            font-family: var(--font-primary);
            font-weight: var(--font-primary-weight-normal);
            cursor: pointer;
            border: none;
            padding: 0 0 15px 0;
            margin-right: 40px;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.02em;
            @media (max-width: 1200px) {
              font-size: 16px;
              font-weight: var(--font-primary-weight-normal);
              line-height: 24px;
              padding: 8px 16px;
            }
            &:hover {
              border-bottom: 1px solid var(--green);
              color: var(--white);
            }
            &.active {
              background-color: transparent;
              border-bottom: 1px solid var(--green);
              color: var(--green);
              @media (max-width: 1200px) {
                border-bottom: 2px solid var(--green);
              }
            }
          }
        }
      }
      .tab-content {
        &-item {
          height: 50px;
          border: 1px solid #303040;
          border-radius: 8px;
          padding: 12px 10px;
          margin-bottom: 8px;
          color: #77778C;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          &-tag {
            display: flex;
            align-items: center;
          }
          &-title {
            margin-bottom: 0;
            margin-left: 19px;
          }
          &-icons {
            display: flex;
            visibility: hidden;
            margin: -4px 0;
            @media (max-width: 1200px) {
              visibility: visible;

            }
          }
          &-link {
            background: #303040;
            border-radius: 8px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
          }
          &-link:hover {
            background: #77778C;
          }
        }
        &-item:hover {
          border: 1px solid #77778C;
          color: #fff;
        }
        .tab-content-item:hover > .tab-content-item-icons {
          visibility: visible;
        }
        .tab-content-item:hover > .tab-content-item-tag > .tab-content-item-title {
          color: #fff;
        }
        .tab-content-item:hover > .tab-content-item-tag > img {
          filter: brightness(0) invert(1);
        }
        .isoImage {
          .tabs_header {
            @media (max-width: 1200px) {
              border-bottom: none;
            }
            .nav-tabs {
              border-bottom: none;
              overflow-x: scroll;
              overflow-y: hidden;
              white-space: nowrap;
              flex-wrap: nowrap;
              margin-top: 24px;
              border-bottom: none;
              &::-webkit-scrollbar {
                padding-top: 20px;
                width: 10px;
                height: 5px;
              }
              &::-webkit-scrollbar-track {
                margin-top: 20px;
                background: var(--grey-darken);
              }
              &::-webkit-scrollbar-thumb {
                background: var(--grey-dark);
                transition: 0.5s;
                width: 20px;
              }
              &::-webkit-scrollbar-thumb:hover {
                background: var(--green);
              }
              .nav-item {
                margin-bottom: 22px;
              }
              @media (min-width: 968px) {
                .nav-item {
                  padding-right: 17px;

                  &:last-child {
                    padding-right: 0;
                  }
                }
              }
              @media (max-width: 1200px) {
                .nav-item {
                  padding-right: 10px;

                  &:last-child {
                    padding-right: 0;
                  }
                }
              }
              @media (min-width: 768px) and (max-width: 1024px) {
                .nav-item {
                  padding-bottom: 20px;
                  margin: 0 5px;
                }
              }
              .nav-link {
                width: 140px;
                height: 140px;
                border-radius: 10px;
                border: 1px solid var(--grey-darken);
                background-color: var(--black-lighter);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: var(--gray);
                font-family: var(--font-secondary);
                font-size: 12px;
                font-weight: var(--font-secondary-weight-normal);
                line-height: 24px;
                text-transform: capitalize;
                padding: 0;

                @media (max-width: 1200px) {
                  width: 120px;
                  height: 120px;
                }
                svg {
                  fill: #4d4d66;
                  margin-bottom: 16px;
                }
                &:hover {
                  border-color: var(--green);
                  color: var(--white);
                  svg {
                    fill: var(--white);
                  }
                }
                &.active {
                  border-color: var(--green);
                  color: var(--white);
                  svg {
                    fill: var(--white);
                  }
                }
              }
            }
            .tabs-noscroll {
              overflow-x: auto;
              justify-content: start;
            }
          }
        }
        .tab-pane {
          padding-top: 40px;
          .button {
            margin-top: 25px;
          }
        }
        .node_distributions-btn.active {
          display: flex;
          .button.size_small {
            min-width: 150px;
          }
          .input-group {
            margin-top: 25px;
          }
        }
      }
    }
    .hiddenBlock {
      position: relative;
      top: -365px;
    }
  }
}
//slider
.slick-slider {
  .slick-list {
    padding-left: 0 !important;

    .slick-track {
      display: flex;
      align-items: center;

      .slick-active {
        .downloadBlock_slider {
          transition: transform 0.4s;
          height: 233px;
          box-shadow: 0 4px 18px 0 #000000d1;
        }
      }
    }
  }

  .slick-dots {
    li {
      &.slick-active {
        button {
          &::before {
            color: var(--white);
          }
        }
      }

      button {
        &::before {
          color: #43434c;
          opacity: 1;
        }
      }
    }
  }
}
.arrow-right {
  transform: rotate(270deg);
  padding-top: 0 !important;
}
.storage-type {
  margin-top: 32px;
}
.safe-storage {
  padding: 8px !important;
}
.safe-storage-title {
  margin-left: 8px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.safe-storage-icons {
  margin: 0 !important;
}
.safe-storage-link {
  margin: 0 !important;
}
.safe-storage:hover > .safe-storage-icons {
  visibility: visible;
}
.safe-storage:hover > .safe-storage-icons img {
  filter: brightness(0) invert(1);
}
.safe-storage-icons img {
  @media (max-width: 1200px) {
    filter: brightness(0) invert(1);
  }
}
.safe-storage:hover > .safe-storage-tag > .safe-storage-title {
  color: #fff;
}
.button-container {
  margin-top: 36px;
}
.light-title {
  font-weight: var(--font-primary-weight-light);
}
