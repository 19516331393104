.headerNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  border-bottom: 1px solid var(--grey-darken);
  background-color: rgba(#18181f, .9);
  width: 100%;
  z-index: 2;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.4s;
  .navbar-brand {
    img {
      width: 263px;
      height: 40px;
    }
  }
  @media (max-width: 768px) {
    height: 68px;
    .navbar-brand {
      img {
        width: 215px;
        height: 32px;
      }
    }
    .navbar-expand-md {
      .navbar-nav {
        flex-direction: column;
      }
    }
    .button {
      text-transform: capitalize;
      width: 100%;
      margin: 0;
    }
  }
  .navbar {
    justify-content: space-between;

    width: 100%;
    max-width: 1943px;
    padding: 0 40px 0 25px;
    ul {
      li{
        color: var(--white);
      }
    }
    @media (max-width: 768px) {
      padding: 0 5px 0 5px;
    }
    &_col {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .nav-item {
    .nav-link {
      color: var(--grey-lighter);
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: var(--font-secondary-weight-normal);
      line-height: 28px;
      text-transform: capitalize;
      cursor: pointer;
      white-space: nowrap;
    }
  }
  &_flex {
    display: flex;
    margin-left: 0.8rem;
    position: relative;
  }
  &_left {
    display: flex;
    position: relative;
    padding-left: 64px;
    .theme_green {
      margin-left: 10px;
      text-transform: capitalize;
      padding: 0 8px;
      min-width: 110px;
    }
    .theme_ghost {
      width: 30px;
    }
  }
  &_sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--black);
    overflow-x: hidden;
    transition: width 0.5s;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 69px;
      padding: 13px 23px 13px 11px;
    }
    &__button {
      display: flex;
      width: 90%;
      justify-content: space-between;
      margin-bottom: 20px;
      a {
        margin-right: 0 !important;
      }
      @media (max-width: 768px) {
        flex-wrap: wrap;
        padding-bottom: 10px;
        .search {
          width: 100%;
        }
      }
    }
    &.open {
      overflow-x: visible;
      width: 100%;
      height: 100%;
    }
    .container {
      flex-direction: column;
      background: var(--black);
    }
    .navbar-nav {
      text-align: center;
      .nav-link {
        font-size: 20px;
        font-weight: 400;
        line-height: 60px;
      }
    }
  }
  &_language_modal {
    max-height: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--black);
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    margin-top: 100px;
    &__header {
      display: flex;
      justify-content: end;
      padding: 45px 75px 45px 27px;
    }
    &.open {
      transition: max-height 0.25s ease-in;
      max-height: 768px;
    }
    .container {
      flex-direction: column;
      background: var(--black);
    }
  }
}
.hamburger-button {
  padding: 13px 12px;
  border: 1px solid var(--grey-darken);
  border-radius: 8px;
  display: flex;
}

.navbar-expand-md .navbar-nav {
  flex-direction: column;
  @media (min-width: 1025px) {
    flex-direction: row;
  }
}


