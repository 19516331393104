.content-container {
  color: var(--grey);
  padding: 81px 20px;
  @media (min-width: 1023px) {
    padding: 81px 128px;
  }
  @media (min-width: 1366px) {
    padding: 81px 328px;
  }

  h1 {
    font-size: 44px;
    color: var(--white);
    font-family: var(--font-primary);
    font-weight: var(--font-primary-weight-bold);
  }
  h2 {
    font-size: 30px;
    color: var(--white);
    font-family: var(--font-primary);
    font-weight: var(--font-primary-weight-bold);
    margin-top: 48px;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 22px;
    color: #fff;
    font-family: var(--font-primary);
    font-weight: var(--font-primary-weight-bold);
    margin-top: 32px;
  }
  p {
    font-family: var(--font-secondary);
    font-weight: var(--font-secondary-weight-normal);
    font-size: 21px;
  }
  a, a:hover, a:visited {
    color: var(--green);
    text-decoration: none;
  }
  ul {
    list-style: disk;
    font-family: var(--font-secondary);
    font-weight: var(--font-secondary-weight-normal);
    font-size: 21px;
  }
  &-sidebar {
    padding: 80px 10px;
    ul {
      list-style: disk;
      font-family: var(--font-secondary);
      font-weight: var(--font-secondary-weight-normal);
      font-size: 21px;
      li {
        a {
          color: var(--grey);
          font-size: 18px;
          &:hover, &:active {
            color: var(--green);
            text-decoration: none;
          }
        }
      }
    }
  }
}
