.select_label {
  margin-top: 28px;
  color: var(--grey);
  font-family: var(--font-secondary);
  font-size: 15px;
  font-weight: var(--font-secondary-weight-normal);
  line-height: 30px;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    margin-top: 23px;
  }
}
.select_input {
  padding: 0 20px;
  margin-bottom: 20px;
  height: 43px;
  border-radius: 5px;
  border: 1px solid var(--gray-darken);
  background-color: var(--black-lighter);
  color: var(--white);
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: var(--font-secondary-weight-normal);
  line-height: 24px;
  -webkit-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--white) 50%),
  linear-gradient(135deg, var(--white) 50%, transparent 50%);
  background-position: calc(100% - 16px) 13px, calc(100% - 12px) 13px;
  background-size: 4px 4px, 4px 4px;
  background-repeat: no-repeat;
  &:focus {
    background-color: var(--black-lighter);
    color: var(--white);
    border-color: var(--gray-darken);
    box-shadow: 0 0 5px 0 var(--green);
  }
}
.indicator-separator {
  display: none;
}
.arrow-down {
  margin: 8px 8px;
}
.arrow-down:hover{
  filter: brightness(0) invert(1);
}
.select-container {
  display: flex;
  margin-top: 56px;
  margin-bottom: 32px;
}
.dropdown-menu {
  position: relative;
  display: inline-block;
}
.button-menu {
  cursor: pointer;
  border: 1px solid #303040;
  background: #18181F;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 17px;
}
.button-menu:hover {
  border: 1px solid #77778C;
}
.button-menu:hover > img {
  filter: brightness(0) invert(1);
}
.select {
  width: 90%;
}
.menu{
  position: absolute;
  width: fit-content;
  z-index: 1;
  display: block;
  background: #24242E;
  border: 1px solid #303040;
  box-sizing: border-box;
  box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
  a {
    padding: 4px 16px;
    text-decoration: none;
    display: block;
    border-radius: 8px;
    //font-family: 'Red Hat Display';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #77778C;
  }
  a:hover {
    background: #303040;
    color: #fff;
  }
}
