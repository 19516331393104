@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Red+Hat+Display:wght@400&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  font-size: var(--font-primary-size);
  font-weight: var(--font-primary-weight-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
}
.page {
  min-height: calc(100vh - 454px);
}

.title {
  color: var(--white);
  font-size: 44px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
}

.title span {
  color: var(--green);
}

.preTitle {
  color: var(--grey-lighter);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.description {
  color: var(--grey);
  font-family: var(--font-secondary);
  font-size: 26px;
  font-weight: var(--font-secondary-weight-normal);
  line-height: 28px;
}

.img-responsive {
  height: auto;
  width: 100%;
  display: block;
}

.scroll-down .headerNavbar {
  transform: translate3d(0, -100%, 0);
}

.scroll-up .headerNavbar {
  transform: none;
}

em {
  font-style: normal;
  color: var(--green);
}

/*media*/

@media (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 1;

  }
  .preTitle {
    font-size: 9px;
  }
  .description {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1295px;
  }
}

:root {
  --green: #46b549;
  --green-darken: #42b649;
  --green-lighter: #64cc6e;
  --grey: #77778c;
  --grey-lighter: #aeaebf;
  --grey-darken: #303040;
  --grey-dark: #4d4d66;
  --gray: #636378;
  --gray-darken: #424259;
  --white-darken: #c5c5d9;
  --white: #fff;
  --black: #18181f;
  --black-lighter: #1c1c24;
  --black-darken: #14141a;
  --font-primary: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;
  --font-primary-size: 20px;
  --font-primary-weight-normal: 400;
  --font-primary-weight-bold: 700;
  --font-secondary: 'Red Hat Display', 'Open Sans', Helvetica, Arial, sans-serif;
  --font-secondary-size: 20px;
  --font-secondary-weight-normal: 400;
}
