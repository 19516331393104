.copy-button{
  background: #303040;
  border-radius: 8px;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.copy-button:hover {
  background: #77778C;
}
