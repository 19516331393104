.languagePage {
  background-color: var(--black);
  //padding-top: 93px;
  padding-bottom: 118px;
  border-bottom: 1px solid var(--grey-darken);
  .animatedArrow {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    padding: 100px 0;
  }
  .description {
    font-size: 17px;
    line-height: 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  h3 {
    line-height: 28px;
    padding: 36px 0 15px;
    color: var(--white);
    font-family: var(--font-secondary);
    font-size: 17px;
    font-weight: var(--font-secondary-weight-normal);
    @media (max-width: 767px) {
      padding: 32px 0;
    }
  }
  &_inProgress {
    @media (max-width: 767px) {
      padding-top: 32px;
    }
    p {
      margin-bottom: 32px;
    }
  }
  &_block {
    display: flex;
    align-items: center;
    padding: 1.5px;
    height: 89px;
    width: 94%;
    border-radius: 6px;
    border: 1px solid var(--grey-darken);
    background-color: var(--black-lighter);
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: .8s;
    &-active {
      border: 1px solid var(--green);
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &.big {
      height: 160px;
      width: 100%;
      text-align: left;
    }
    &__text {
      position: absolute;
      padding: 16px 25px;
      background: var(--black-lighter);
      width: 99%;
      border-radius: 6px;
      p {
        color: var(--white);
        font-family: var(--font-primary);
        font-weight: var(--font-primary-weight-normal);
        font-size: 16px;
        margin-bottom: 0;
      }
      span {
        color: var(--grey);
        font-family: var(--font-secondary);
        font-size: 15px;
        font-weight: var(--font-secondary-weight-normal);
        line-height: 22px;
        display: block;
      }
      a {
        padding-top: 20px;
      }
    }

  }
  ol {
    counter-reset: mycounter;
    list-style-type: decimal-leading-zero;
    padding-inline-start: 30px;
    li {
      padding: 0 0 15px 10px;
     &::marker {
      color: var(--white);
      font-family: var(--font-secondary);
      font-size: 22px;
      font-weight: var(--font-secondary-weight-normal);
    }
      p {
        margin-bottom: 0;
      }
      a {
        color: var(--green);
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
