.button {
  display: inline-block;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  text-align: center;
  margin-right: 15px;
  padding: 0 24px;
  text-transform: uppercase;
  text-decoration: none;
  @media (max-width: 767px) {
    &.mobileFullWidth {
      width: 100%;
    }
  }
  &.hover {
    transition: all .2s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    //transform: scale(1.03);
  }
  &:active,
  &:focus {
    outline: none;
  }
  &.margin {
    &_0 {
      margin-right: 0;
    }
    &_10 {
      margin-right: 10px;
    }
    &_15 {
      margin-right: 15px;
    }
    &_35 {
      margin-right: 35px;
    }
  }
  &.size {
    &_normal {
      height: 65px;
      min-width: 230px;
      border-radius: 8px;
      font-size: 14px;
      color: #ffffff;
      font-family: var(--font-primary);
      font-weight: var(--font-primary-weight-bold);
      @media (max-width: 767px) {
        font-size: 12px;
        height: 47px;
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }
    &_small {
      min-width: 110px;
      height: 40px;
      border-radius: 8px;
      font-size: 12px;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 0.1em;
      font-family: var(--font-primary);
      font-weight: var(--font-primary-weight-bold);
    }
    &_extraSmall {
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      font-size: 14px;
      color: #ffffff;
      font-family: var(--font-primary);
      font-weight: var(--font-secondary-weight-normal);
      line-height: 24px;
      padding: 11px;
      .body{
        height: 16px;
        width: 16px;
        .body_icon {
          height: 16px;
          width: 16px;
          margin-right: 0;
          svg {
            margin-top: -10px;
          }
        }
      }
    }
  }
  &.theme {
    &_green {
      background-color: var(--green);
    }
    &_ghost {
      border: 1px solid var(--gray-darken);
      background-color: var(--black);
      &:hover {
        border-color: var(--green);
      }
    }
  }
  & + .button {
    margin-right: 0;
  }
  .body{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .body_icon {
      margin-right: 8px;
      & + .body_text {
        padding-left: 15px;
      }
    }
  }
}
a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
