.heroHeader {
  background-color: var(--black);
  display: flex;
  align-items: center;
  &_container {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  &_bg {
    background: url(../../images/sphere-hero.webp) no-repeat 17% -23%;
    padding-top: 30vh;
    max-width: 1943px;
    @media (max-width: 991px) {
      background-image: url(../../images/sphere_hero_sm.png);
      background-position: 0 -78px;
      background-size: 100%;
      padding-top: 350px;
    }
    @media (max-width: 767px) {
      background-position: 0 -28px;
      padding-top: 180px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .title {
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.03em;
    font-weight: var(--font-primary-weight-bold);
    text-align: center;
    font-family: var(--font-primary);
    margin-bottom: 24px;
    text-transform: none;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }
  }
  .description {
    line-height: 32px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 72px;
    @media (max-width: 767px) {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .mouseIcon {
    margin: 4.7% auto 0;
    width: 21px;
    height: 29px;
    &_mouse {
      width: 3px;
      padding: 4px 8px;
      height: 26px;
      border: 2px solid #434359;
      border-radius: 25px;
      opacity: 0.75;
      box-sizing: content-box;
      &__scroller {
        width: 3px;
        height: 7px;
        border-radius: 50%;
        background-color: #fff;
        animation-name: scroll;
        animation-duration: 3s;
        animation-timing-function: cubic-bezier(.15,.41,.69,.94);
        animation-iteration-count: infinite;
      }
      @keyframes scroll {
        0% { opacity: 0; }
        10% { transform: translateY(0); opacity: 1; }
        100% { transform: translateY(15px); opacity: 0;}
      }
    }
  }
  &_icon {
    text-align: center;
    margin: 11px auto 0;
  }
}
